import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Layout from '../../components/layout';
import Seo from '../../components/seo';

const ImageToJpegConverter = () => {
  const [originalFilename, setOriginalFilename] = useState('');
  const [convertedImage, setConvertedImage] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const convertImage = async (file) => {
    if (!file) {
      setError('Please select a file');
      return;
    }

    setIsLoading(true);
    setError(null);
    setConvertedImage(null);

    try {
      let jpegBlob;
      
      if (file.type === 'image/png') {
        // For PNG files, convert directly using canvas
        const img = new Image();
        const loadPromise = new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
        });
        
        img.src = URL.createObjectURL(file);
        await loadPromise;
        
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        
        // Set white background for PNG with transparency
        ctx.fillStyle = '#FFFFFF';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        
        ctx.drawImage(img, 0, 0);
        
        // Convert to JPEG blob
        canvas.toBlob((blob) => {
          const jpegUrl = URL.createObjectURL(blob);
          setConvertedImage(jpegUrl);
          setIsLoading(false);
        }, 'image/jpeg', 0.8);
      } else {
        // Existing HEIC conversion logic
        const heic2any = (await import('heic2any')).default;
        jpegBlob = await heic2any({
          blob: file,
          toType: "image/jpeg",
          quality: 0.8
        });
        
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          
          setConvertedImage(canvas.toDataURL('image/jpeg'));
          setIsLoading(false);
        };
        img.onerror = () => {
          setError('Error loading the converted image.');
          setIsLoading(false);
        };
        img.src = URL.createObjectURL(jpegBlob);
      }
    } catch (error) {
      console.error('Conversion failed:', error);
      setError('Conversion failed. Please try again.');
      setIsLoading(false);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      // Store the original filename without extension
      const filename = file.name.replace(/\.[^/.]+$/, '');
      setOriginalFilename(filename);
      convertImage(file);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop,
    accept: {
      'image/heic': ['.heic'],
      'image/heif': ['.heif'],
      'image/png': ['.png']
    },
    multiple: false
  });

  return (
    <Layout>
      <Seo 
        title="Image to JPEG Converter" 
        description="Convert HEIC/HEIF/PNG images to JPEG format online. Free, fast, and secure converter that works directly in your browser without uploading files to any server."
      />
      <div className="max-w-2xl mx-auto p-4">
        <h1 className="text-3xl font-bold mb-4">Image to JPEG Converter</h1>
        {isClient && (
          <div 
            {...getRootProps()} 
            className={`border-2 border-dashed p-8 mb-4 text-center cursor-pointer ${
              isDragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
            }`}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the image file here ...</p>
            ) : (
              <p>Drag 'n' drop a HEIC or PNG file here, or click to select a file</p>
            )}
          </div>
        )}
        {isLoading && (
          <div className="flex justify-center items-center mb-4">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            <p className="ml-3">Converting image...</p>
          </div>
        )}
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {convertedImage && (
          <div className="mt-4">
            <h3 className="text-xl font-semibold mb-2">Converted Image:</h3>
            <img src={convertedImage} alt="Converted" className="max-w-full h-auto mb-2" />
            <a 
              href={convertedImage} 
              download={`${originalFilename}.jpg`}
              className="inline-block bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
            >
              Download JPEG
            </a>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ImageToJpegConverter;